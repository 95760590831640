import { Endpoint } from '@shared'
import { ApplicationErrorCode } from '@ui-common-types'
import axios, { AxiosError, AxiosRequestConfig } from 'axios'
import { StatusCodes } from 'http-status-codes'
import * as R from 'remeda'

import { sendEndpointRetryEventToAnalytics, sendErrorEventToAnalytics } from '../../components/common/analytics'

export interface CustomRequestConfig {
  maxRetries?: number
  retryDelayMs?: number
  maxRetryAfterMs?: number
}

export interface CustomAxiosRequestConfig extends AxiosRequestConfig {
  customRequestConfig?: CustomRequestConfig
}

export interface EndpointParameters<
  Request = void,
  QueryParams extends string = string,
  PathParams extends string = string,
> {
  queryParams?: Record<QueryParams, string>
  pathParams?: Record<PathParams, string>
  requestPayload?: Request
  requestConfig?: CustomRequestConfig
}

export const getCustomRequestConfig = (error: AxiosError): CustomRequestConfig | undefined => {
  return (error.config as CustomAxiosRequestConfig)?.customRequestConfig
}

export const callEndpoint = async <Response, Request, QueryParams extends string, PathParams extends string>(
  endpoint: Endpoint<Response, Request, QueryParams, PathParams>,
  params: EndpointParameters<Request, QueryParams, PathParams> = {},
  skipCacheBusting: boolean = false,
): Promise<Response> => {
  const { requestConfig, queryParams, pathParams, requestPayload } = params
  const axiosConfig: CustomAxiosRequestConfig = {
    params: { ...(skipCacheBusting ? {} : { ts: Date.now() }), ...queryParams },
  }
  if (requestConfig) {
    axiosConfig.customRequestConfig = requestConfig
    if (requestConfig.maxRetries !== undefined && requestConfig.maxRetries >= 0) {
      axiosConfig['axios-retry'] = { retries: requestConfig.maxRetries }
      axiosConfig['axios-retry'].onRetry = (retryCount, error) =>
        sendEndpointRetryEventToAnalytics(retryCount, endpoint?.method, endpoint?.path, error)
    }
  }

  let url = endpoint.path
  if (pathParams) {
    for (const pathParam of Object.entries<string>(pathParams)) {
      url = url.replace(`{${pathParam[0]}}`, pathParam[1])
    }
  }

  let data: Response
  try {
    switch (endpoint.method) {
      case 'get':
        ;({ data } = await axios.get<Response>(url, axiosConfig))
        break
      case 'put':
        ;({ data } = await axios.put<Response>(url, requestPayload, axiosConfig))
        break
      case 'post':
        ;({ data } = await axios.post<Response>(url, requestPayload, axiosConfig))
        break
      case 'delete':
        ;({ data } = await axios.delete<Response>(url, axiosConfig))
        break
      default:
        throw new Error(`Unsupported endpoint method: ${endpoint.method}`)
    }
    return data
  } catch (error) {
    if (R.isError(error)) {
      sendErrorEventToAnalytics(`${endpoint.method} ${endpoint.path} failed: ${error.message}`, error)
    } else {
      sendErrorEventToAnalytics(`${endpoint.method} ${endpoint.path} failed: ${JSON.stringify(error)}`)
    }
    throw error instanceof AxiosError
      ? error?.status === StatusCodes.ACCEPTED
        ? { errorCode: ApplicationErrorCode.ResultNotAvailable, ...error }
        : error?.response?.data
      : {} // TODO: throwing anything else than original error here feels incorrect
  }
}
