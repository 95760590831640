import { DomesticAddress, ForeignAddress } from '@shared'

export enum PaymentFreeMonthsCount {
  Two = 2,
  One = 1,
  None = 0,
}

export enum CommonQueryParam {
  InitialLanguage = 'initialLang',
  SourceChannel = 'src',
  Flow = 'flow',
  Target = 'target',
  SkipBffStatusCheck = 'skipStatusCheck',
}

export enum MaintenancePageQueryParams {
  DigiPlatformAppName = 'app',
}

export enum CreditLimitCategory {
  Large = 'large',
  Medium = 'medium',
  Small = 'small',
}

export const PHONE_COUNTRY_CODE = '+358'

export enum StorageKey {
  WizardState = 'wizard-state',
  Language = 'lang',
  Fingerprint = 'fp',
  SignStarted = 'signStarted',
  PaymentStarted = 'aoPaymentMachine-state',
  PaymentSubmit = 'adyen-checkout__checkout-attempt-id',
  PaymentRedirected = 'paymentRedirected',
  BackFromPayment = 'backFromPayment',
}

export enum CsatDifficultyLevel {
  VeryEasy = 'veryEasy',
  Easy = 'easy',
  Neutral = 'neutral',
  Difficult = 'difficult',
  VeryDifficult = 'veryDifficult',
}

export enum YesNo {
  Yes = 'yes',
  No = 'no',
}

export type NamedPerson = { firstName: string; lastName: string }

export interface WithAddressInfo {
  hasDomesticAddress?: YesNo
  domesticAddress?: DomesticAddress
  foreignAddress?: ForeignAddress
}

export type ConditionalValues<T extends object> = {
  [Property in keyof T]-?: boolean
}

export enum ApplicationErrorCode {
  ResultNotAvailable = 'resultNotAvailable',
}

export interface ApplicationError extends Error {
  errorCode: ApplicationErrorCode
}
