import { assertValue, roundNumberToFractionDigits } from '@shared/helpers'
import {
  CommonMimeType,
  ConfigResponse,
  Country,
  ENDPOINT_GET_CONFIG,
  ErrorResponse,
  FileObject,
  PhoneNumber,
  StorageDataAbbreviation,
} from '@shared/types'
import { allowedCharsRule } from '@shared/validation'
import { ProgressBarStepProps } from '@ui-components/global/ComponentProps'
import { COMMON_PLACEHOLDER } from '@ui-components/global/constants'
import { PrimaryTopBarProps } from '@ui-components/navigation/PrimaryTopBar/PrimaryTopBar'
import {
  PAGE_BACK_BUTTON_CLASS,
  PAGE_EXIT_BUTTON_CLASS,
  PAGE_LOADER_CLASS,
  PAGE_TITLE_CLASS,
} from '@ui-components/utilities/helpers'
import { useEffect, useState } from 'react'
import { IntlShape } from 'react-intl'
import * as R from 'remeda'
import { v4 as uuidv4 } from 'uuid'

import { CommonQueryParam, ConditionalValues, NamedPerson, StorageKey, YesNo } from '../types'
import { callEndpoint } from './http/client'
import { getLocalStorageItem, getSessionStorageItem, removeSessionStorageItem, setLocalStorageItem } from './storage'

export const CHANGING_TEXT_DEFAULT_DELAY_MS = 15_000
export const BACK_BUTTON_TIMEOUT_MS = 60_000

export const COUNTRY_CODE_FINLAND = 'FI'

type BlobTypes = 'application/octet-stream' | 'application/pdf'
export const base64ToBlob: (data: string, type: BlobTypes, size?: number) => Blob = (data, type, sample = 512) => {
  const buffer = window.atob(data)
  const bytes: Uint8Array[] = []

  for (let offset = 0; offset < buffer.length; offset += sample) {
    const slice = buffer.slice(offset, offset + sample)
    const sliceBytes = new Array(slice.length)
    for (let y = 0; y < slice.length; y++) {
      sliceBytes[y] = slice.charCodeAt(y)
    }
    const byteArray = new Uint8Array(sliceBytes)
    bytes.push(byteArray)
  }
  return new Blob(bytes, { type })
}

export const isIosUserAgent = (): boolean => {
  const userAgent = window.navigator.userAgent.toLowerCase()
  return userAgent.includes('iphone') || userAgent.includes('ipad')
}

const isModalOpen = (): boolean => {
  return !!document?.querySelector('[aria-modal="true"]')
}

export const updatePageFocus = (): ReturnType<typeof setTimeout>[] => {
  if (!document) {
    return []
  }

  const timeoutIds: ReturnType<typeof setTimeout>[] = []

  const timeoutId1 = setTimeout(() => {
    if (isModalOpen()) {
      return
    }

    const titleElement = document?.querySelector(`.${PAGE_TITLE_CLASS}`)
    if (titleElement instanceof HTMLElement) {
      titleElement.focus()
      return
    }

    const timeoutId2 = setTimeout(() => {
      const loadingElement = document?.querySelector(`.${PAGE_LOADER_CLASS}`)
      if (loadingElement instanceof HTMLElement && document.activeElement !== loadingElement) {
        loadingElement.focus()
      }
    }, 90)
    timeoutIds.push(timeoutId2)
  }, 10)
  timeoutIds.push(timeoutId1)

  return timeoutIds
}

export const debugLog = (...messages: unknown[]): void => {
  if (import.meta.env?.VITE_ENABLE_DEBUG_LOG) {
    // eslint-disable-next-line no-console
    console.debug(...messages)
  }
}

export const useChangingText = (
  initialText: string,
  secondText: string,
  delayMilliSeconds = CHANGING_TEXT_DEFAULT_DELAY_MS,
): string => {
  const [text, setText] = useState(initialText)

  useEffect(() => {
    const timer = setTimeout(() => {
      setText(secondText)
    }, delayMilliSeconds)
    return () => clearTimeout(timer)
  }, [delayMilliSeconds, secondText])

  return text
}

export const defaultToString = (value: undefined | number, defaultStr = ''): string =>
  typeof value === 'undefined' ? defaultStr : value.toString()

export const skipBffStatusCheck = (): boolean => {
  const params = new URLSearchParams(window.location.search)
  return params.get(CommonQueryParam.SkipBffStatusCheck) === 'true'
}

export const navigateBackHandler = (_e?: PopStateEvent): void => {
  const pushToHistory = () => {
    history.pushState(null, document.title, location.href)
  }
  if (isModalOpen()) {
    pushToHistory()
    return
  }

  const backButton = document.querySelector(`.${PAGE_BACK_BUTTON_CLASS}`)
  const exitButton = document.querySelector(`.${PAGE_EXIT_BUTTON_CLASS}`)
  if (backButton instanceof HTMLElement) {
    pushToHistory()
    backButton.click()
  } else if (exitButton instanceof HTMLElement) {
    pushToHistory()
    exitButton.click()
  }
}

export const resolveAndStoreParam = <T extends string>(
  param: CommonQueryParam,
  validValues: T[],
  defaultValue: T,
  storageKey?: string,
): T => {
  const queryParameters = new URLSearchParams(window.location.search)
  let value = queryParameters.get(param) as T
  const key = storageKey || param
  if (!validValues.includes(value)) {
    value = getLocalStorageItem(key) || defaultValue
  }
  setLocalStorageItem(key, value)
  return value
}

export const getFlow = <T extends string>(): T | null => {
  return getLocalStorageItem(CommonQueryParam.Flow)
}

export const getTarget = <T extends string>(): T | null => {
  return getLocalStorageItem(CommonQueryParam.Target)
}

export const getSourceChannel = <T extends string>(): T | null => {
  return getLocalStorageItem(CommonQueryParam.SourceChannel)
}

export const getEntranceStepTopBarProps = (
  intl: IntlShape,
  onExitClick: (event: React.BaseSyntheticEvent) => unknown,
  onLanguageChange: () => unknown,
): PrimaryTopBarProps => {
  return {
    actionButtonOptions: {
      label: intl.formatMessage({ id: 'common-exit' }),
      onClick: onExitClick,
    },
    bottomBorder: false,
    changeLanguageButtonOptions: {
      label: intl.formatMessage({ id: 'common-toggle-language' }),
      onClick: onLanguageChange,
    },
  }
}

export const isPageAccessedByReload = (
  window.performance.getEntriesByType('navigation') as PerformanceNavigationTiming[]
)
  .map((navigation) => navigation.type)
  .includes('reload')

export const timestamp = (): string => {
  return Date.now().toString()
}

export const isWithinBackButtonTimeout = (storageKey: StorageKey): boolean => {
  const startTimeStr = getSessionStorageItem(storageKey)
  const startTime = startTimeStr ? parseInt(startTimeStr) : null

  if (!!startTime && isNumber(startTime) && startTime + BACK_BUTTON_TIMEOUT_MS >= Date.now()) {
    return true
  } else {
    removeSessionStorageItem(storageKey)
    return false
  }
}

export const formatFinnishCurrencyValue = (
  value: string | number,
  minimumFractionDigits = 2,
  maximumFractionDigits = 2,
): string => {
  return new Intl.NumberFormat('fi-FI', { minimumFractionDigits, maximumFractionDigits }).format(
    Number(toNumber(value)),
  )
}

export const toFinnishCurrencyOrPlaceholder = (value: number | undefined): string => {
  return isNumber(value) ? formatFinnishCurrencyValue(value) : COMMON_PLACEHOLDER
}

export const toInteger = <T extends string | undefined>(
  value: T,
  defaultStr = '',
): T extends undefined ? number | undefined : number => {
  if (typeof value === 'undefined' || value === defaultStr) {
    return undefined as T extends undefined ? number | undefined : number
  }
  return parseInt(value)
}

export const numberToString = (value: number | undefined | null, fractionDigits = 2): string | undefined => {
  const number = toNumber(value)
  if (number === undefined || number === null) {
    return undefined
  }
  return `${roundNumberToFractionDigits(value ?? 0, fractionDigits)}`.replace(/\./g, ',')
}

export const toNumber = (value: number | string | undefined | null): number | undefined => {
  switch (typeof value) {
    case 'string': {
      const number = parseFloat(simplifyNumberString(value))
      return isNumber(number) ? number : undefined
    }
    case 'number':
      return isNumber(value) ? value : undefined
    default:
      return undefined
  }
}

export const isNumber = (candidate: unknown): candidate is number => {
  if (typeof candidate !== 'number') {
    return false
  }
  return isFinite(candidate)
}

const simplifyNumberString = (stringValue: string): string =>
  stringValue?.replace(/,/g, '.').replaceAll(' ', '').replaceAll('\xa0', '')

export const formatNumber = (stringValue: string): number => Number(simplifyNumberString(stringValue))

export const getYesNoDefault = (
  hasParentObject: boolean,
  data: boolean | number | undefined,
  defaultValue: YesNo | undefined = undefined,
): YesNo | undefined => {
  if (!hasParentObject) {
    return undefined
  }
  if (data === undefined || data === null) {
    return defaultValue
  }
  // resolve true and positive number to YesNo.Yes
  const bool = isNumber(data) ? data > 0 : data
  return resolveBooleanInputToYesNo(bool)
}

export const resolveBooleanInputToYesNo = (value: boolean | undefined): YesNo | undefined => {
  if (value === true) {
    return YesNo.Yes
  }
  if (value === false) {
    return YesNo.No
  }
  return undefined
}

export const resolveYesNoToBooleanInput = (value: YesNo | undefined): boolean | undefined => {
  if (value === YesNo.Yes) {
    return true
  }
  if (value === YesNo.No) {
    return false
  }
  return undefined
}

export const resolveFormEmptyString = (input: string | undefined | null): string | undefined | null => {
  if (input === '') {
    return null
  }
  return input
}

export const isErrorResponse = (error: unknown): error is ErrorResponse<never> => {
  return !!error && (error as ErrorResponse<never>).retryable !== undefined
}

export const isFileOfCorrectType = (file: File, allowedFileType: string[]): boolean => {
  return allowedFileType.includes(file.type)
}

export const isFileSizeWithinLimit = (file: File, allowedFileSize: number): boolean => {
  return file.size <= allowedFileSize
}

export const trimFileNameToContainNoExtension = (fileName: string): string => {
  return fileName.includes('.') ? fileName.substring(0, fileName.lastIndexOf('.')) : fileName
}

export const isFileNameLengthWithinLimit = (file: File, allowedNameLength: number): boolean => {
  const trimmedFileName = trimFileNameToContainNoExtension(file.name)
  return trimmedFileName.length <= allowedNameLength
}

interface GenerateFileObjectArrayReturnType {
  valid: FileObject[]
  invalid: FileObject[]
}

export const generateFileObjectArray = ({
  files,
  allowedFileType,
  allowedFileSize,
  allowedFileNameLength,
  intl,
}: {
  files: File[]
  allowedFileType: string[]
  allowedFileSize: number
  allowedFileNameLength: number
  intl: IntlShape
}): GenerateFileObjectArrayReturnType => {
  const validArray: FileObject[] = []
  const invalidArray: FileObject[] = []

  files.forEach((file: File) => {
    const returnedFile = {
      id: uuidv4(), //For valid array this will be replaced by useDynamicFormField when item is added there
      file,
      isValid: false,
      name: file.name,
      size: file.size,
    }

    if (!isFileOfCorrectType(file, allowedFileType)) {
      invalidArray.push({
        ...returnedFile,
        error: intl.formatMessage({ id: 'common-wrong-file-format' }),
      })
      return
    }

    if (!isFileSizeWithinLimit(file, allowedFileSize)) {
      invalidArray.push({
        ...returnedFile,
        error: intl.formatMessage({ id: 'common-file-exceed-size-limit' }),
      })
      return
    }

    if (!isFileNameLengthWithinLimit(file, allowedFileNameLength)) {
      invalidArray.push({
        ...returnedFile,
        error: intl.formatMessage({ id: 'common-file-exceed-name-length-limit' }, { limit: allowedFileNameLength }),
      })
      return
    }
    const illegalChars = getIllegalCharacters(file.name)
    if (illegalChars.length > 0) {
      invalidArray.push({
        ...returnedFile,
        error: intl.formatMessage(
          { id: 'common-file-illegal-characters' },
          { length: illegalChars.length, chars: illegalChars },
        ),
      })
      return
    }

    validArray.push({
      ...returnedFile,
      isValid: true,
    })
  })

  return {
    valid: validArray,
    invalid: invalidArray,
  }
}

export const resolveAllowedTypesString = (fileTypes: CommonMimeType[], separator: ', ' | ' ' = ' '): string => {
  const extensions: Record<CommonMimeType, string> = {
    [CommonMimeType.Png]: '.png',
    [CommonMimeType.Jpeg]: '.jpg',
    [CommonMimeType.Pdf]: '.pdf',
    [CommonMimeType.MsWordDoc]: '.doc',
    [CommonMimeType.MsWordDocx]: '.docx',
  }

  return fileTypes.map((type) => extensions[type]).join(separator)
}

export const resolveMapValue = <TKey extends string, TValue>(
  record: Record<TKey, TValue>,
  key: TKey | undefined | null,
): TValue | undefined => {
  if (key === undefined || key === null) {
    return undefined
  }
  return record[key]
}

export const getStorageDataAbbreviation = (intl: IntlShape): StorageDataAbbreviation => {
  return {
    bytes: intl.formatMessage({ id: 'common-bytes' }),
    kilobytes: intl.formatMessage({ id: 'common-kilobytes' }),
    megabytes: intl.formatMessage({ id: 'common-megabytes' }),
  }
}

export const getIllegalCharacters = (input: string | null | undefined): string => {
  if (!input) {
    return ''
  }

  const allowedCharsRegExp = new RegExp(allowedCharsRule)
  const nonWhitespaceCharacterRegExp = /\S/

  const illegalCharacters = R.unique(
    input.split('').filter((char) => !allowedCharsRegExp.test(char) && nonWhitespaceCharacterRegExp.test(char)),
  )

  return illegalCharacters.length > 6 ? illegalCharacters.slice(0, 6).join('') + '…' : illegalCharacters.join('')
}

export const getFullname = (namedObject: Partial<NamedPerson>): string =>
  `${namedObject.firstName ?? ''} ${namedObject.lastName ?? ''}`.trim()

export const formatPhoneNumber = (phoneNumber: PhoneNumber): string =>
  `${phoneNumber.phoneCountryCode} ${phoneNumber.phoneNumber}`

export const copyToClipboard = async (elementId: string): Promise<void> => {
  const element = document.getElementById(elementId)
  await navigator.clipboard.writeText(assertValue(element?.textContent, `getElementById(${elementId}).textContent`))
}

export const getCountryName = (countries: Country[], countryId?: string): string =>
  countries?.find((country) => country && country.alpha2Code === countryId)?.name ?? ''

export function removeConditionalValues<T extends object, R extends ConditionalValues<T>>(
  values: T,
  conditionalValues: R,
): T {
  const ret: Record<string, unknown> = {}
  for (const [key, included] of Object.entries(conditionalValues)) {
    if (included) {
      ret[key] = values[key as keyof T]
    }
  }
  return ret as T
}

export const getProgressText = (intl: IntlShape, progressBarProps: ProgressBarStepProps): string => {
  return intl.formatMessage(
    { id: 'common-intro-section-progress-label' },
    { currentStepIndex: progressBarProps.currentStepIndex, totalSteps: progressBarProps.totalSteps },
  )
}

export const getCountryLabel = (country: Country): string => {
  return `${country.name} (+${country.phoneCode})`
}

export const getConfig = async <TParam extends string>(
  sourceChannel: string,
  flow: string,
): Promise<ConfigResponse<TParam>> => {
  return callEndpoint(ENDPOINT_GET_CONFIG, { queryParams: { sourceChannel, flow } })
}
